.amFilesContainerDesktop{
    position: absolute !important;
    width: 420px;
    margin: auto;
    height: 65vh;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    bottom: 50px;
   
}

.amFilesContainerMobile{
    width: 105%;
    bottom: -40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50%;
    left: -15px;
}

.amFilesContainerIframe{
    width: 80%;
    bottom: -150px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50%;
   
}

.amFilesContainerInnerMobile{
    transition: 0.5s;
    height: 100%;
    width: 86%;
    margin-left: 7% ;
    margin-right: 7%;
}

.amFileContainerInnerMobile{
    transition: 0.5s;
    height: 100%;
    width: 80%;
    margin-left: 10% ;
    margin-right: 10%;
}

.amFileContainerInnerDesktop{
    transition: 0.5s;
    height: 50%;
    width: 89%;
    margin-left: 5.5%;
    margin-right: 5.5%;
    margin-top: 42% !important;
}

.amSenderFileContainer{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color:#f9f9f9c7;
    height: 30vh;
    border-radius: 10px;
    
}
.amInputFiles{
    position: absolute !important;
    bottom: 8px;
    width: 90%;
    height: 44px;
    background-color: var(--primary-color);
    border-radius: 6px;
}
.amInputFiles_input {
    display: none;
}

.amInputFileUpLoad{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.amFilesLabel{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.amFilesIconContainer{
    width: 25px;
    height: 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
}

.amFilesIconReceivedContainer{
    width: 25px;
    height: 25px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.amFilesIconReceivedContainer img {
    margin-right: 5px;
}


/* .amFilesIconContainer img {
    color: var(--primary-color);
} */
.amfileTextupload{
    font-size: 14px;
    font-weight: 700;
    color: white;
    margin: 0px !important;
    padding-top: 13px;
}

.amfileTextupload p{
    margin: 0px !important;
}


.amFileSendContainer{
    position: absolute !important;
    bottom: 10px;
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.amFileSendInner{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}
.amFilesTitle{
    width: 90%;
    position: absolute;
    top: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1.5px solid grey;
}

.amFilesTitle p {
    font-size: 12px;
    font-weight: 700;
    color: black;
}




.fileSelectedInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}
.fileSelectedInfo p {
    font-size: 8px;
    height: 10px;
    color: #527FC0;
    margin: 0px !important;
}

.fileReceivedInfo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}
.fileReceivedInfo p {
    font-size: 12px;
    color: #527FC0;
    margin: 0px !important;
}


/* .amFilesReceivedIn{
   
    width: 90%;
    border-bottom: 1.5px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.amFilesReceivedIn{
   /*  position: absolute !important; */
    top: 50px;
    width: 90%;
    height: 14vh;
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-bottom: 1.5px solid grey;
    overflow-y: auto;
}

/* .amFilesReceivedIn{
    height: 14vh;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
} */

.amFileReceivedInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
  }
.amFileReceivedInner img {
    margin: 0px, 5px;
}

.amFileReceivedContainer{
    height: 14vh;
    width: 90%;
}

.amFilesReceivedIn::-webkit-scrollbar {
    -webkit-appearance: none;
}

.amFilesReceivedIn::-webkit-scrollbar:vertical {
    width:3px;
    height: 5px;
}

.amFilesReceivedIn::-webkit-scrollbar-button:increment,.amFilesReceivedIn::-webkit-scrollbar-button {
    display: none;
} 

.amFilesReceivedIn::-webkit-scrollbar:horizontal {
    height: 5px;
}

.amFilesReceivedIn::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
   /*  border: 2px solid #f1f2f3; */
}

.amFilesReceivedIn::-webkit-scrollbar-track {
    border-radius: 10px;  
}

.amFileDetail {
    display: flex;
    align-items: center;
    width: 100%;
}