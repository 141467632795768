.iconContainer{
    display: flex;
    justify-content: center;
    max-width: 20%;
   margin: auto;
   margin-top: 20px;
}

.waitingScreenText{
    font-family: var(--primary-font);
    margin: auto;
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
    font-size: 0.8rem;
    color: black;

    .bigger{
        font-size: 1.3rem;
    }
}


.smallMarginHorizontal {
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
  }


.mediaMedium{
    height: 260px;
    margin-top: 25px;
}

.containerSlide{
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    border-radius: 20px;
    width: 70%;
    height: 90%;
    padding: 15px;  
    color: white;
}

.slideText {
    font-size: 0.8rem;
}

.slideTextIframe {
    font-size: 0.6rem;
}


#client{
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.containerSlideClient{
    flex-direction: column;
    display: flex;
    justify-content: space-around;
    border-radius: 20px;
    width: 270px;
    background-color: var(--primary-color);
    color: white;
}

.blueBackground{
    background-color: var(--primary-color);
    min-height: 490px;
}

.containerCall{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 25px;
}

@media only screen and (max-height: 780px) {
    .containerCall {
        margin-bottom: 15px;
    }
} 


.slideContainer{
    --background: none;
    text-align: center;
    align-items: center;
}

.slidesContainer{
    max-width: 300px;
}

.lowerPart{
    position: absolute;
    background-color: white;
    bottom: -35%;
    width: 100%;
    border-radius: 20px;
}

.vectorContainer{
    margin-top: 45px;
    height: auto;
}

@media screen and (min-width: 800px)  {
    .vectorContainer{
        margin-top: 0px;
    }
}


.slideWidth{
    padding-left: 11%;
    padding-right: 11%;
}

.video-add{
    transform: none !important;
    -webkit-transform: none !important;
    width: 100%    !important;
    height: auto   !important;
}