.errorColor {
    color: var(--cancel-color)
}

.errorIcon {
    height: 70px;
    width: 70px;
    color: white;
    background-color: var(--cancel-color);
    border-radius: 155px;
    --ionicon-stroke-width: 50px;
}

.negativeTop{
    margin-top: -8px;
}