.antCallContainer {
  height: 72vh;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.antCallContainerOnCall {
  height: 100vh;
  width: 100%;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* VIDEO */

#localVideoSource {
  height: 105px;
  width: 75px;
  position: absolute;
  max-width: 210px;
  border-radius: 8px !important;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
  z-index: 9999999999999999;
}

.iosNoVideo {
  height: 105px;
  width: 75px;
  position: absolute;
  max-width: 210px;
  border-radius: 8px !important;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
  z-index: 9999999999999999;
  background: black;
}

#localVideoIos {
  height: 105px;
  width: 75px;
  position: absolute;
  max-width: 210px;
  border-radius: 8px !important;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}

#noLocalVideo {
  background-color: var(--primary-color);
  height: 105px;
  width: 78px;
  /*  position: absolute; */
  max-width: 210px;
  border-radius: 8px !important;
  right: 1.5rem;
  top: 30px;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}
#localVideo {
  height: 105px;
  width: 74px;
  /* position: absolute; */
  max-width: 210px;
  border-radius: 8px !important;
  z-index: 100;
  margin-left: 1.5rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}

.localVideoMobile {
  height: 105px;
  width: 74px;
  position: fixed !important;
  border-radius: 8px !important;
  z-index: 90;
  top: 113px;
  left: 1px;
}

.localVideoMobileOnCall {
    height: 105px;
    width: 74px;
    position: fixed ;
    border-radius: 8px !important;
    z-index: 90;
    top: 10px;
    left: 1px;
  }

#noLocalVideoCameraOff {
  background-color: black;
  height: 105px;
  width: 75px;
  position: absolute;
  max-width: 210px;
  border-radius: 8px !important;
  right: 1.5rem;
  top: 30px;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}

#screenVideo {
  height: 105px;
  width: 75px;
  position: absolute;
  max-width: 210px;
  border-radius: 8px !important;
  right: 1.5rem;
  z-index: 100;
  top: 30px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
  transform: rotate(180deg);
}

#remoteVideo {
  position: fixed;
  height: 103vh;
  width: 100vw;
  left: 0px;
  top: 0px;
  transform: rotate(360deg); 
}

.remoteVideoDesktopContainer {
  position: absolute !important;
  width: 70vw;
  margin: auto;
  height: 75vh;
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
}

.remoteVideoDesktop {
  max-width: 100% !important;
  border-radius: 15px;
  max-height: 102% !important;
}

.draggableVideo {
  position: absolute;
  /* height: 100vh!important; */
  width: 100vw !important;
  right: 0px;
  top: 30px;
}

.draggableVideoHidden {
  display: none !important;
}

.localVideoDesktop {
  max-width: 210px !important;
  border-radius: 8px !important;
  z-index: 100 !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
  margin-left: 15px;
}

.noVideoDesktop > img {
  width: 100%;
}

.noVideoMobile {
  background-color: var(--primary-color);
  position: fixed !important;
  width: 78px !important;
  height: 107px !important;
  border-radius: 8px !important;
  z-index: 100 !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
  margin-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 24px;
  top : 113px !important
}
.noVideoMobileOnCall {
    background-color: var(--primary-color);
    position: fixed !important;
    width: 78px !important;
    height: 107px !important;
    border-radius: 8px !important;
    z-index: 100 !important;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
    margin-right: 15px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 24px;
    top: 10px !important
  }

.noVideoDesktop {
  background-color: var(--primary-color);
  /* position: absolute !important; */
  max-width: 210px !important;
  border-radius: 8px !important;
  z-index: 100 !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
  margin-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noVideoMobile > img {
  width: 100%;
}

.localVideoDesktopContainer {
  position: absolute !important;
  width: 420px;
  margin: auto;
  height: 75vh;
  padding-top: 15px;
  display: flex;
  justify-content: flex-start;
}

#videoSource {
  position: absolute;
  z-index: 999999;
  width: 80%;
  background: white;
  border: solid 1px var(--primary-color);
  border-radius: 15px;
  text-align: center;
  padding-bottom: 15px;
  box-shadow: -1px -1px 400px 200px rgb(68, 67, 67);
  -webkit-box-shadow: -1px -1px 400px 200px rgb(68, 67, 67);
  -moz-box-shadow: -1px -1px 400px 200px rgb(68, 67, 67);
}

.Toastify__close-button Toastify__close-button--light {
  display: none !important;
}

#iframeLinkMobile {
  z-index: 999;
  width: 90vw;
  height: 80vh;
  margin: 0;
  left: 2.5vh;
  top: 10vh;
  position: absolute;
}

.iframeCloseMobile {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;;
  z-index: 999999;
  right: 0;
  padding-right: 15px;
  top: 30px;
  font-weight: bold;
  font-size: 1.5em;
}

.iframeCloseMobile > span{
  width: 20%;
  background-color: var(--primary-color);
  color: white;
  font-size: .5em;
  text-align: center;
  padding: 3px;
  border-radius: 8px;
}

#iframeLinkDesktop {
  border-radius: 15px;
  z-index: 999999999999;
  border: none;
  width: 90vw;
  margin-left: 5vw;
  height: 90vh;
  margin-top: 2.5vh;
}

.iframeCloseDesktop {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: black;
  position: relative;
  z-index: 999999;
  right: 0;
  padding-right: 5px;
  font-weight: bold;
  font-size: 1.5em;
  top: 10px;
}

.iframeCloseDesktop > span{
  width: 8%;
  background-color: white;
  color: var(--primary-color);
  font-size: .5em;
  text-align: center;
  padding: 2px;
  border-radius: 8px;
}

.iframeContainerMobile {
  width: 420px;
  height: 70%;
  margin-left: auto;
  border-radius: 15px;
  z-index: 99999;
}

.iframeContainerDesktop {
  position: absolute;
  width: 100vw;
  height: 98%;
  left: 0;
  top: 0;
  z-index: 9999;
}

.noVideoDesktopCameraOff {
  background-color: black;
  max-width: 210px !important;
  border-radius: 8px !important;
  z-index: 100 !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
  margin-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px !important;
  right: 0 !important;
}

.noVideoMobileCameraOff {
  background-color: black;
  position: absolute;
  max-width: 210px !important;
  border-radius: 8px !important;
  z-index: 100 !important;
  box-shadow: 0px 0px 0px rgb(0 0 0 / 2%), 0 0px 5px 3px rgb(0 0 0 / 2%);
  margin-right: 15px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px !important;
  right: 0 !important;
}
