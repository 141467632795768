.textArea {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100px;
    max-height: 100px;
    min-height: 100px;
    padding: 0.5rem;
    border: 0px solid #ddd;
    font-size: 0.75rem;
    text-align: left;
    margin-bottom: 2rem;
    letter-spacing: 1px;
    background-color: white;
}