@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

.colorStripe {
  background-color: var(--primary-color);
  height: 40px;
  width: 100%;
  top: 0px;
  z-index: 10;
  position: sticky;
}

.pointer {
  cursor: pointer;
}

.App-mask-white {
  margin-top: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  color: black;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-mask-white-Desktop {
  margin-top: 10px;
  width: 75%;
  height: 100%;
  margin: auto;
  margin-top: 20px;
  position: relative;
  color: black;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .App-mask-white-Desktop {
    width: 100%;
  }
}

.camWarn {
  font-weight: 600;
  font-size: 1.3rem;
  color: var(--primary-color);
  margin-bottom: 16px !important;
}

.startCallContainer {
  font-family: var(--primary-font);
  text-align: center;
  width: 90%;
}

.acceptAllButton {
  height: 93px;
  width: 90%;
  background-color: #359309;
  color: white;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 13px;
  cursor: pointer;
}

.acceptMicButton {
  height: 47px;
  width: 90%;
  background-color: #656565;
  color: white;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 13px;
  cursor: pointer;
}

.permissionsTextAll {
  margin-top: 32px;
  margin-left: 3px;
  font-size: 11px;
  text-align: left;
  font-family: "Open Sans";
}

.permissionsTextMic {
  margin-top: 16px;
  margin-left: 3px;
  font-size: 9px;
  text-align: left;
  font-family: "Open Sans";
}

.permissionsTextCancel {
  margin-top: 14px;
  font-size: 12px;
  font-family: "Open Sans";
}

.permissionImg {
  margin-top: 30px;
}

.topPadding {
  margin-top: 15%;
}
.topLessPadding {
  position: relative;
  margin-top: 20px;
}

.imgFrame {
  filter: drop-shadow(0 2mm 2mm #d5d5d5);
  p img {
    border-radius: 20px;
  }
}

.disclaimerCam {
  //font-size: 1.1rem;
  //margin-bottom: 30px;
  font-size: 1rem;
  margin-bottom: 5px;
  color: black;
  line-height: 1.1rem;
  //margin-top: 30px;
  margin-top: 10px;
  text-align: center;
  display: inline-block;
  line-height: inherit;
  font-weight: bold;
  font-family: var(--secondary-font);
}

.startCall {
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  height: 130px;
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1%;

  .double {
    width: 70px;
  }

  .textButton {
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    max-width: 80%;
    line-height: 0.8rem;
    p {
      margin-bottom: 0;
      font-weight: 100;
    }
  }

  img {
    margin: auto;
  }
  .iconsButton {
    display: flex;
    margin: auto;
    margin-top: 40px;
  }
}
.startCallDesktop {
  font-family: var(--primary-font);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  height: 93px;
  width: 43%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1%;

  .double {
    width: 75px;
  }

  .textButton {
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-left: 10px;
    margin-right: 0px;
    max-width: 80%;
    line-height: 0.8rem;
    p {
      font-size: 0.8rem;
      margin-bottom: 0;
      font-weight: 100;
    }
  }

  img {
    margin: auto;
  }

  .iconsButtonDesktop {
    display: flex;
    justify-content: space-around;
  }
}

.importants {
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 15px;

  p {
    font-size: 0.8rem;
    margin: 2px;
    font-family: var(--primary-font);
  }
}

.bold {
  font-weight: bolder;
  color: black;
}

.footFree * {
  position: relative;
}
.footDown {
  position: absolute;
  bottom: 0px;
}
.buttonPermits {
  width: 90%;
  justify-content: space-around;
}

.rejectButton {
  background-color: #ee1010;
  border-radius: 15px;
  color: white;
  width: 90%;
  text-align: center;
  height: 47px;
  margin-bottom: 13px;
}
.alertBackground {
  position: absolute;
  width: 100%;
  height: 100%;
}
.alertContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  position: absolute;
  text-align: center;
  color: black;
  font-size: 0.9rem;
  font-weight: 400;
  box-shadow: 0px 7px 20px 2px grey;

  .rejectButtons {
    display: flex;
    justify-content: space-around;
    margin: none;
    margin-top: 25px;
    margin-bottom: 0;
    :nth-child(2) {
      border-left: 2px solid black;
    }
    div {
      margin-top: 10px;
      padding: 15px;
      width: 100%;
      max-height: 1rem;
    }
  }
}

.screenPermitsOne {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  border-radius: 20px;
  width: 270px;
  border: 5px solid var(--primary-color);
  //background-color: var(--primary-color);
  color: white;
  height: 25vh;
}
.screenPermitsCamera {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  //padding: 10px;
}

.handClick{
position: absolute;
//left: 67%;
right: 6%;
//top: 82.39%;
bottom: 5%;
}

.screenPermitsCamera p {
  text-align: center;
  color:var(--primary-color) ;
}
.screenPermitsIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: transparent;
    width: 100%;
    position: fixed;
    bottom: 3%;

    @media screen and (min-width: 800px) {
      position: absolute;
      bottom: -20%;
    }
    

}

.screenPermitsIcons img {
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.screenPermitsIconsItems {
 display: flex;
 justify-content: center;
 align-items: center;
    width: 6vh;
    min-width: 45px;
    height: 4vh;
    min-height: 45px;
    margin: 10px 0px !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-color: var(--primary-color);
    background-color: lightgrey;
    background-repeat: no-repeat;
    cursor: pointer;

}

.screenPermitsIconsItemsRed {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  width: 6vh;
  min-width: 45px;
  height: 4vh;
  min-height: 45px;
  margin: 10px 0px !important;
  border: 2px solid white;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.screenPermitsCameraImg {
  height: 80px;
  margin-top: 10px;
  width: 80px;
}

.screenPermitsImages {
  display: flex;
  flex-direction: row;
}
.cameraOff {
  position: absolute;
  width: 70px;
  height: 105px;
  max-width: 210px;
  border-radius: 8px !important;
  top: -1rem ;
  right: 1.5rem;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}

.cameraOffIframe{
  position: absolute;
  width: 70px;
  height: 105px;
  max-width: 210px;
  border-radius: 8px !important;
  top: -1rem ;
  left: 45vw;
  z-index: 100;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
}

.iconsPermits{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}
