ion-slides {
    height: 100%;
    :host(#background-content){
      background-color: black;
    }
  }

ion-content {
    height:270px
}

@media only screen and (max-height: 780px) {
  ion-content {
    height:240px
  }
} 

  .slides{
      padding: 10px;
      p{
        display: inline-block;
        text-align: center;
        max-width: 220px;
      }
  }


.sliderContent{
  --bullet-background-active:white;
  
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
}

@media only screen and (min-width: 600px){
  .swiper-pagination-bullet {
    width: 14px;
    height: 14px;
  }
}