.modal {
    
}

.closeButton {
    width: fit-content !important;
    right: 10px;
    display: flex;
    align-self: flex-end;
    margin-right: 20px;
    --background: #F0F0F0;
    --color: black;
}