.subheader::before {
    content: "";
    height: 100px;
    width: 100px;
    background: var(--secondary-color) content-box;
    border-radius: 50%;
    background-image: var(--logo);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    padding: 0.5em;
    box-sizing: border-box;
  }
  
.subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  width: 92px;
  border-radius: 50%;
  top: -85px;

  z-index: 10;
}

.contentContainer{
  background-color: white;
  border-radius: 20px;
  position: relative;
  top: -2.5%;
  padding-right: 10%;
  padding-left: 10%;
}

.contentContainerFull{
  background-color: white;
  border-radius: 20px;
  position: relative;
}

.content{
    padding-top: 40px;
} 

.contentIframe{
  padding-top: 0px;
}

.borderFrame{
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.ion-page{
  height: 100% !important;
}

.app-desktop{
  margin-left: auto;
  border-radius: 15px;
  background-color: white;
  width: 420px;
  max-height: 75vh;
  overflow-y: scroll;
  overflow: auto; /*Graceful degradation para Firefox*/
  overflow: overlay;

}

.app-desktop-inner{
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 15px;
  overflow: auto; /*Graceful degradation para Firefox*/
  overflow: overlay;
}

#appscroll::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#appscroll::-webkit-scrollbar{
	width: 12px;
  display: none;
	background-color: #F5F5F5;
}

#appscroll::-webkit-scrollbar-thumb{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}


.text-desktop {
  width: 35vw;
  padding-top: 15%;
}

.social-container {
  align-items: center;
  display: flex;
}

.social-pic {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.app-desktop-container{
  display: flex;
  margin: auto;
  width: 70vw;
  margin-top: var(--wrapper-top);
  justify-content: space-between;
}

.app-new-desktop-container{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.social-icon{
  border-radius: 10px;
  color: var(--secondary-color);
  margin-right: 10px;
}

.desktop-background{
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.desktop-cancel{
  float: right;
  color: white;
  text-decoration: underline;
  cursor: pointer;
  width: 410px;
  margin-right: 15vw;
  text-align: center;
  margin-top: 5px;
}

.bodyhtml {
  a{
    color: var(--secondary-color);
  }
}